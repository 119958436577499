import {
	parseDate
} from "../../utils/convert.js"
export default {
	name: 'Event',
	data() {
		return {
			minOptionDate:0,
			maxOptionDate:0,			
			get pickerOptions(){
				var that= this
				return {
					onPick: (obj) => {
						that.minOptionDate = new Date(obj.minDate).getTime();
						that.maxOptionDate = new Date(obj.maxDate).getTime();
					},
					disabledDate(time) {
						//就是这个之前的和这个之后的都
						if(that.minOptionDate>0 || that.maxOptionDate>0){//如果两个都选择了，那应该是根据哪个看起呢
							var threeMonthsAfter = null;
							var threeMOnthsBefore = null;
							let three = 59 * 24 * 3600 * 1000;
							if(that.minOptionDate>0){//这样应该怎么来设定呢？？？？								
								threeMonthsAfter = that.minOptionDate + three;
							}
							if(that.maxOptionDate>0){
								threeMOnthsBefore = that.minOptionDate - three;								
							}
							
							return time.getTime() > Date.now() || 
								(threeMonthsAfter!=null && time.getTime() > threeMonthsAfter) || 
								(threeMOnthsBefore!=null && time.getTime() < threeMOnthsBefore);
						}else{
							return time.getTime() > Date.now();
						}
					},
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近两个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
							picker.$emit('pick', [start, end]);
						}
					}]
				}
			},
			searchTime: null,
			chartInstance: null,
			getPatientDetailUuid: '',
			//时间选择器选择时间差
			iDays: 90,
			eventTableData: [],
			OverviewData: [],
			loading: true,

		}
	},
	props: {
		puid: String
	},
	created() {
		this.getSearchTime();
	},
	methods: {
		changeDate(){
			this.minOptionDate=0;
			this.maxOptionDate=0;
		},
		//默认选择90天日期
		getSearchTime() {
			let nowDate = new Date();
			if (sessionStorage.getItem('searchTime')) {
				console.log(sessionStorage.getItem('searchTime'))
				var searchTime = sessionStorage.getItem('searchTime').split(',')
				this.searchTime = [searchTime[0], searchTime[1]];
			} else {
				let endTime = parseDate(nowDate);
				let startTime = parseDate(new Date(nowDate.setDate(nowDate.getDate() - 13)));
				this.searchTime = [startTime, endTime];
				sessionStorage.setItem("searchTime", this.searchTime);
			}
			this.doSearchTime();
		},
		doPDFExport() {
			//个人信息
			document.querySelector('.el-collapse-item__wrap').style.display='block';
			document.querySelector('.el-tabs__header').style.display='none';
			document.querySelector('.pdfshow').style.display='none';
			document.querySelector('.pdftitle').style.display='block';
			document.querySelector('.event-totalwarp').style.boxShadow='inherit';
			console.log(document.querySelector('#forPDF'))
			this.$PDFSave(document.querySelector('#forPDF'), '事件统计');
			document.querySelector('.el-collapse-item__wrap').style.display='none';
			document.querySelector('.event-totalwarp').style.boxShadow='0 0 8px #ddd';
			document.querySelector('.pdfshow').style.display='block';
			document.querySelector('.pdftitle').style.display='none';
			document.querySelector('.el-tabs__header').style.display='block';
		},
		//点击查询按钮按照日期查询
		doSearchTime() {
			const entMeasureTime = new Date(Date.parse(this.searchTime[1].replace(/-/g, "/")));
			const startMeasureTime = new Date(Date.parse(this.searchTime[0].replace(/-/g, "/")));
			this.iDays = parseInt(Math.abs(entMeasureTime.getTime() - startMeasureTime.getTime()) / 1000 / 60 / 60 /
				24) + 1;
			if (sessionStorage.getItem('searchTime')) {
				var searchTime = sessionStorage.getItem('searchTime').split(',')
				if (searchTime[0] !== this.searchTime[0] || searchTime[1] !== this.searchTime[1]) {
					sessionStorage.setItem("searchTime", this.searchTime);
				}
			} else {
				sessionStorage.setItem("searchTime", this.searchTime);
			}
			var params = {
				userUuid: this.puid,
				endTime: this.searchTime[1] + ' 23:59:59',
				startTime: this.searchTime[0] + ' 00:00:00',
			}
			this.$data.OverviewData = Object.assign([], this.$data.OverviewData); //清空对象
			this.$axios.post('/api/his/web/event/getAll', params).then(response => {
				if (response.data.code == 200) {
					// post 成功，response.data 为返回的数据
					this.OverviewData = response.data.data;
					console.log(this.OverviewData)
					this.eventTableData = response.data.data.listDTOs;
					if (this.eventTableData !== null) {
						this.eventTableData.forEach(item => {
							item.key = parseInt(item.time)
						})
						this.eventTableData.sort(this.compare('key'));
					}
				}
				this.loading = false;
			}).catch(error => {
				// 请求失败
				console.log(error)
				/* this.$message({
					message: "请求失败121",
					type: "error"
				}); */
			})
		},
		compare(key) {
			return function(a, b) {
				var val1 = a[key];
				var val2 = b[key];
				return val1 - val2;
			}
		}
	},
};
