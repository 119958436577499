import {
	xData
} from "../../utils/chartXData.js"
import {
	parseDate
} from "../../utils/convert.js"

export default {
	name: 'Overview',
	data() {
		return {
			loading: true,
			minOptionDate:0,
			maxOptionDate:0,			
			get pickerOptions(){
				var that= this
				return {
					onPick: (obj) => {
						that.minOptionDate = new Date(obj.minDate).getTime();
						that.maxOptionDate = new Date(obj.maxDate).getTime();
					},
					disabledDate(time) {
						//就是这个之前的和这个之后的都
						if(that.minOptionDate>0 || that.maxOptionDate>0){//如果两个都选择了，那应该是根据哪个看起呢
							var threeMonthsAfter = null;
							var threeMOnthsBefore = null;
							let three = 59 * 24 * 3600 * 1000;
							if(that.minOptionDate>0){//这样应该怎么来设定呢？？？？								
								threeMonthsAfter = that.minOptionDate + three;
							}
							if(that.maxOptionDate>0){
								threeMOnthsBefore = that.minOptionDate - three;								
							}
							
							return time.getTime() > Date.now() || 
								(threeMonthsAfter!=null && time.getTime() > threeMonthsAfter) || 
								(threeMOnthsBefore!=null && time.getTime() < threeMOnthsBefore);
						}else{
							return time.getTime() > Date.now();
						}
					},
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近两个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
							picker.$emit('pick', [start, end]);
						}
					}]
				}
			},
			searchTime: null,
			//时间选择器选择时间差
			iDays: 90,
			OverviewData: {},
			chartInstance: null,
			oveRieoGauge: null,
			hbgiColorVeryLow: null,
			hbgiColorLow: null,
			hbgiColorVeryHigh: null,
			hbgiColorHigh: null,
			xAxisDataAll: [],
			Width: '',
			//高血糖曲线
			yaxisdatamax: [],
			//低血糖曲线
			yaxisdatamin: [],
			//平均血糖曲线
			yaxisdataaverage: [],
			oveCgmcurve: '',
			LBGIlbgiVeryLow: '当 LBGI<1.1 时，有极小低血糖风险。',
			LBGIlbgiLow: '当 1.1<=LBGI<2.5 时，有较少低血糖风险。',
			LBGIlbgiVeryHigh: '当 2.5<=LBGI<5 时，有中等低血糖风险。',
			LBGIlbgiHigh: '当 LBGI>5.0 时，有较高低血糖风险。',

			HBGIlbgiVeryLow: '当 HBGI<5.0 时，有极小高血糖风险。',
			HBGIlbgiLow: '当 5.0<=HBGI<10.0 时，有较少高血糖风险。',
			HBGIlbgiVeryHigh: '当 10.0<=LBGI<15.0 时，有中等高血糖风险。',
			HBGIlbgiHigh: '当 LBGI>15.0 时，有较高高血糖风险。',
		}
	},
	props: {
		puid: String,
		uname: String
	},
	created() {
		window.addEventListener('resize', this.innerWidth);
		this.getWidth();

		// this.loading = this.$loading({
		// 	lock: true,
		// 	text: '拼命加载中...',
		// 	spinner: 'el-icon-loading',
		// 	background: 'rgba(0, 0, 0, 0.7)',
		// 	target: this.$refs.overview
		// })

		this.getSearchTime();
		this.getxAxisDataAll();
	},
	destroyed() {
		window.removeEventListener('resize', this.innerWidth)
	},
	methods: {
		changeDate(){
			this.minOptionDate=0;
			this.maxOptionDate=0;
		},
		getWidth() {
			this.Width = window.innerWidth - 282 + 'px';
		},
		//x轴整体数据
		getxAxisDataAll() {
			var xAxisData = [];
			var xMinute = [];
			var xHour = [];
			for (var min = 0; min < 60; min++) {
				var setMin = min + '';
				if (setMin < 10) {
					setMin = '0' + setMin
				}
				xMinute.push(setMin)
			}
			for (var hour = 0; hour < 24; hour++) {
				var setHour = hour + '';
				if (setHour < 10) {
					setHour = '0' + setHour
				}
				xHour.push(setHour);
			}
			for (var h = 0; h < 24; h++) {
				for (var s = 0; s < 60; s++) {
					var setTime = xHour[h] + ":" + xMinute[s]
					xAxisData.push(setTime);
				}
			}
			this.xAxisDataAll = xAxisData;
		},
		//默认选择90天日期
		getSearchTime() {
			let nowDate = new Date();
			if (sessionStorage.getItem('searchTime')) {
				var searchTime = sessionStorage.getItem('searchTime').split(',')
				this.searchTime = [searchTime[0], searchTime[1]];
			} else {
				let endTime = parseDate(nowDate);
				let startTime = parseDate(new Date(nowDate.setDate(nowDate.getDate() - 13)));
				this.searchTime = [startTime, endTime];
				sessionStorage.setItem("searchTime", this.searchTime);
			}
			this.doSearchTime();
		},
		doPDFExport() {
			document.querySelector('.el-collapse-item__wrap').style.display = 'block';
			document.querySelector('.el-tabs__header').style.display = 'none';
			document.querySelector('.pdfshow').style.display = 'none';
			document.querySelector('.pdftitle').style.display = 'block';
			document.querySelector('.ove-rieo').style.boxShadow = 'inherit';
			this.$PDFSave(document.querySelector('#forPDF'), '概览');
			document.querySelector('.el-collapse-item__wrap').style.display = 'none';
			document.querySelector('.ove-rieo').style.boxShadow = '0 0 8px #ddd';
			document.querySelector('.pdfshow').style.display = 'block';
			document.querySelector('.pdftitle').style.display = 'none';
			document.querySelector('.el-tabs__header').style.display = 'block';
		},
		//点击查询按钮按照日期查询
		doSearchTime() {
			//console.log(this.searchTime)
			const entMeasureTime = new Date(Date.parse(this.searchTime[1].replace(/-/g, "/")));
			const startMeasureTime = new Date(Date.parse(this.searchTime[0].replace(/-/g, "/")));
			this.iDays = parseInt(Math.abs(entMeasureTime.getTime() - startMeasureTime.getTime()) / 1000 / 60 / 60 /
				24) + 1;
			if (sessionStorage.getItem('searchTime')) {
				var searchTime = sessionStorage.getItem('searchTime').split(',')
				if (searchTime[0] !== this.searchTime[0] || searchTime[1] !== this.searchTime[1]) {
					sessionStorage.setItem("searchTime", this.searchTime);
				}
			} else {
				sessionStorage.setItem("searchTime", this.searchTime);
			}
			//sessionStorage.removeItem("searchTime")
			var params = {
				userUuid: this.puid,
				endMeasureTime: this.searchTime[1] + ' 23:59:59',
				startMeasureTime: this.searchTime[0] + ' 00:00:00',
			}
			//console.log("params", params)
			this.yaxisdatamax.splice(0, this.yaxisdatamax.length); //清空数组
			this.yaxisdatamin.splice(0, this.yaxisdatamin.length); //清空数组
			this.yaxisdataaverage.splice(0, this.yaxisdataaverage.length); //清空数组
			this.$data.OverviewData = Object.assign([], this.$data.OverviewData); //清空对象
			//console.log(params)
			this.$axios.post('/api/his/web/patientCgmData/findReport', params)
				.then(response => {
					// post 成功，response.data 为返回的数据
					this.OverviewData = response.data.data;
					//console.log(this.OverviewData)
					if (parseFloat(this.OverviewData.hbgi) < 5) {
						this.hbgiColorVeryLow = true
					} else if (parseFloat(this.OverviewData.hbgi) < 10) {
						this.hbgiColorLow = true
					} else if (parseFloat(this.OverviewData.hbgi) < 15) {
						this.hbgiColorVeryHigh = true
					} else if (parseFloat(this.OverviewData.hbgi) >= 15) {
						this.hbgiColorHigh = true
					}
					this.initchart();
					this.initoveRieoGauge();
					var vm = this;
					var xAxisDataAll = this.xAxisDataAll;
					//高血糖曲线
					var yaxisdatamax = new Array(1440);
					if (this.OverviewData.max !== null) {
						this.OverviewData.max.forEach(function(item1) {
							for (var setnum = 0; setnum < 1440; setnum++) {
								if (item1.time == xAxisDataAll[setnum]) {
									yaxisdatamax[setnum] = item1.glucose;
								}
							}
							vm.yaxisdatamax = yaxisdatamax;
						})
					}
					//平低血糖曲线
					var yaxisdatamin = new Array(1440);
					if (this.OverviewData.min !== null) {
						this.OverviewData.min.forEach(function(item1) {
							for (var setnum = 0; setnum < 1440; setnum++) {
								if (item1.time == xAxisDataAll[setnum]) {
									yaxisdatamin[setnum] = item1.glucose;
								}
							}
							vm.yaxisdatamin = yaxisdatamin;
						})
					}
					//平均血糖曲线
					var yaxisdataaverage = new Array(1440);
					if (this.OverviewData.average !== null) {
						this.OverviewData.average.forEach(function(item1) {
							for (var setnum = 0; setnum < 1440; setnum++) {
								if (item1.time == xAxisDataAll[setnum]) {
									yaxisdataaverage[setnum] = item1.glucose;
								}
							}
							vm.yaxisdataaverage = yaxisdataaverage;
						})
					}
					this.getCgmcurve();

					// this.loading.close();
					this.loading = false;
				})
			/* .catch(error => {
				// 请求失败
				console.log(error)
				this.$message({
					message: "请求失败",
					type: "error"
				});
			}) */
		},
		initchart() {
			var inRangePrecision = 1 - this.OverviewData.veryHighPrecision - this.OverviewData.highPrecision - this
				.OverviewData.lowPrecision - this.OverviewData.veryLowPrecision
			//console.log(inRangePrecision)
			//console.log(this.OverviewData.inRangePrecision)
			var avePie = [{
				value: this.OverviewData.veryHighPrecision,
				name: "   " + parseFloat((this.OverviewData.veryHighPrecision * 100).toFixed(2)) +
					'%  非常高 ≥14mmol/L'
			}, {
				value: this.OverviewData.highPrecision,
				name: "   " + parseFloat((this.OverviewData.highPrecision * 100).toFixed(2)) +
					'%  较高 10.1-13.9mmol/L'
			}, {
				value: inRangePrecision,
				name: "   " + parseFloat((inRangePrecision * 100).toFixed(2)) + '%  合理 3.9-10mmol/L',
				textStyle: {
					fontSize: 18,
					fontWeight: 'bold'
				}
			}, {
				value: this.OverviewData.lowPrecision,
				name: "   " + parseFloat((this.OverviewData.lowPrecision * 100).toFixed(2)) +
					'%  较低 3-3.8mmol/L'
			}, {
				value: this.OverviewData.veryLowPrecision,
				name: "   " + parseFloat((this.OverviewData.veryLowPrecision * 100).toFixed(2)) +
					'%  非常低 ≤2.9mmol/L'
			}]
			this.chartInstance = this.$echarts.init(this.$refs.seller_ref);
			const initOption = {
				tooltip: {
					trigger: 'item',
					formatter: '{b0}'
				},
				title: {
					text: "TIR",
					left: "116",
					top: "5",
				},
				legend: {
					orient: 'vertical',
					//left: 'right',
					top: "30",
					right: '6%',
					itemGap: 30,
					align: "left",
					itemWidth: 14,
					data: avePie
				},
				series: [{
					name: 'TIR',
					type: 'pie',
					avoidLabelOverlap: false,
					label: {
						show: false,
						position: 'center'
					},
					labelLine: {
						show: false
					},
					itemStyle: {
						borderColor: '#fff',
						borderWidth: 0.2
					},
					width: 190,
					left: 40,
					top: -20,
					color: ['#FFA74A', '#FAD555', '#26AB40', '#FF6060', '#B93333'],
					data: avePie
				}]
			};
			this.chartInstance.setOption(initOption);
			window.addEventListener("resize", () => {
				this.chartInstance.resize();
			});
		},
		initoveRieoGauge() {
			if (this.OverviewData.lbgi == null) {
				this.OverviewData.lbgi = 0;
			}
			this.oveRieoGauge = this.$echarts.init(this.$refs.ove_rieo_gauge);
			const initOptions = {
				title: {
					text: "LBGI低血糖风险指数",
					left: "6%",
					bottom: "30",
					textStyle: {
						fontSize: 14,
						fontWeight: "normal",
						color: "#494949"
					},
				},
				series: [{
					type: 'gauge',
					min: 0,
					max: 10,
					radius: 75,
					center: ["50%", "50%"],
					axisLine: {
						lineStyle: {
							width: 12,
							color: [
								[0.11, '#fad555'],
								[0.25, '#f6a147'],
								[0.5, '#ff6060'],
								[1, '#b73232'],
							],

						}
					},
					pointer: {
						itemStyle: {
							color: 'auto'
						},
						length: '70%',
						width: 3
					},
					axisTick: {
						distance: 3,
						length: 2,
						lineStyle: {
							color: '#fff',
							width: 2
						}
					},
					//仪表盘数字
					splitLine: {
						distance: 30,
						length: 20,
						lineStyle: {
							color: '#fff',
							width: 1
						}
					},
					axisLabel: {
						color: '#464646',
						fontSize: 12,
						distance: 4,
					},
					detail: {
						valueAnimation: true,
						formatter: '{value}',
						color: 'auto',
						fontSize: 22,

					},
					data: [{
						value: this.OverviewData.lbgi
					}]
				}]
			};
			this.oveRieoGauge.setOption(initOptions);
			window.addEventListener("resize", () => {
				this.oveRieoGauge.resize();
			});
		},
		getCgmcurve() {
			var cgmtime = this.searchTime[0] + "  到  " + this.searchTime[1] + '的血糖曲线图'
			this.oveCgmcurve = this.$echarts.init(this.$refs.ove_cgmcurve);
			const curveOption = {
				tooltip: {
					trigger: 'axis',
					formatter(params){
						var startTime = new Date();
						startTime.setHours(0);
						startTime.setMinutes(0);
						startTime.setMinutes(startTime.getMinutes() + params[0].dataIndex);     
						var hour=  startTime.getHours();
						var mins = startTime.getMinutes();
						if(hour<10)
							hour="0"+hour;
						if(mins<10)
							mins="0" + mins;
						var valueStr = params[0].value;
						if(valueStr==null || valueStr==undefined || valueStr=="N/A")
							return null;
						else{
							var tipStr="";
							for(var i=0;i<params.length;i++){
								if(params[i].componentIndex==1){
									tipStr += "<br/>最高血糖: " + params[i].value.toFixed(1) + " mmol/L";
								}else if(params[i].componentIndex==2){
									tipStr += "<br/>平均血糖: " + params[i].value.toFixed(1) + " mmol/L";
								}else if(params[i].componentIndex==3){
									tipStr += "<br/>最低血糖: " + params[i].value.toFixed(1) + " mmol/L";
								}
							}
							tipStr += "<br/>时间: " + hour+":"+mins;
							tipStr=tipStr.substring(5);
							return tipStr;
						}
					}
				},
				grid: {
					left: '60',
					right: '25',
					bottom: '50'
				},
				legend: {
					right: '0',
					icon: "roundRect",
				},
				title: {
					text: cgmtime,
					left: "56",
					top: "1",
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: xData,
					axisLabel: {
						interval: 0
					},
					axisTick: {
						interval: 59
					}
				},
				yAxis: {
					type: 'value',
					min: 2,
					max: 22,
				},
				series: [{
					type: 'line',
					markArea: { //标记区域
						data: [
							[{
								yAxis: '3.9',
								itemStyle: {
									color: '#4870FF23'
								}
							}, {
								yAxis: '10'
							}]
						]
					},
					//分割线
					/* markLine: {
						symbolSize: [8, 14],
						silent: true,
						data: [{
							yAxis: 3.9,
							lineStyle: {
								color: '#f33131',
								width: 1.5,
								type: 'solid'
							},
						}, {
							yAxis: 10,
							lineStyle: {
								color: '#f1b907',
								width: 1.5,
								type: 'solid'
							},
						}]
					}, */
					data: []
				}, {
					name: '最高血糖',
					smooth: true,
					symbol: 'none',
					type: 'line',
					color: '#f1b907',
					stack: 'Total',
					lineStyle: {
						color: '#f1b907'
					},
					data: this.yaxisdatamax
				}, {
					name: '平均血糖',
					smooth: true,
					symbol: 'none',
					type: 'line',
					color: '#4870FF',
					lineStyle: {
						color: '#4870FF'
					},
					data: this.yaxisdataaverage
				}, {
					name: '最低血糖',
					smooth: true,
					symbol: 'none',
					type: 'line',
					color: '#f33131',
					lineStyle: {
						color: '#f33131'
					},
					data: this.yaxisdatamin
				}]
			};
			this.oveCgmcurve.setOption(curveOption);
			window.addEventListener("resize", () => {
				this.oveCgmcurve.resize();
			});
		},
	},
};
