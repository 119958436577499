import StatsDaily from "./stats/StatsDaily.vue";
import StatsWeekly from "./stats/StatsWeekly.vue";
import { parseDate } from "../../utils/convert.js"
export default {
	name: 'Stats',
	components: {
		StatsDaily,
		StatsWeekly
	},
	data() {
		return {
			minOptionDate:0,
			maxOptionDate:0,			
			get pickerOptions(){
				var that= this
				return {
					onPick: (obj) => {
						that.minOptionDate = new Date(obj.minDate).getTime();
						that.maxOptionDate = new Date(obj.maxDate).getTime();
					},
					disabledDate(time) {
						//就是这个之前的和这个之后的都
						if(that.minOptionDate>0 || that.maxOptionDate>0){//如果两个都选择了，那应该是根据哪个看起呢
							var threeMonthsAfter = null;
							var threeMOnthsBefore = null;
							let three = 59 * 24 * 3600 * 1000;
							if(that.minOptionDate>0){//这样应该怎么来设定呢？？？？								
								threeMonthsAfter = that.minOptionDate + three;
							}
							if(that.maxOptionDate>0){
								threeMOnthsBefore = that.minOptionDate - three;								
							}
							
							return time.getTime() > Date.now() || 
								(threeMonthsAfter!=null && time.getTime() > threeMonthsAfter) || 
								(threeMOnthsBefore!=null && time.getTime() < threeMOnthsBefore);
						}else{
							return time.getTime() > Date.now();
						}
					},
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近两个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
							picker.$emit('pick', [start, end]);
						}
					}]
				}
			},
			searchTime: null,
			//时间选择器选择时间差
			iDays: 60,
			statsActiveName: 'daily',

			startTime: '',
			endTime: '',
			doSearch:'',

			pdfTitle: ''
		}
	},
	props: {
		puid: String
	},
	created() {
		this.getSearchTime();
	},
	methods: {
		changeDate(){
			this.minOptionDate=0;
			this.maxOptionDate=0;
		},
		//默认选择60天日期
		getSearchTime() {
			let nowDate = new Date();
			if (sessionStorage.getItem('searchTime')) {
				console.log(sessionStorage.getItem('searchTime'))
				var searchTime = sessionStorage.getItem('searchTime').split(',')
				this.searchTime = [searchTime[0], searchTime[1]];
			} else {
				let endTime = parseDate(nowDate);
				let startTime = parseDate(new Date(nowDate.setDate(nowDate.getDate() - 13)));
				this.searchTime = [startTime, endTime];
				sessionStorage.setItem("searchTime", this.searchTime);
			}
			this.doSearchTime();
		},
		doSearchTime(){
			const entMeasureTime = new Date(Date.parse(this.searchTime[1].replace(/-/g, "/")));
			const startMeasureTime = new Date(Date.parse(this.searchTime[0].replace(/-/g, "/")));
			this.iDays = parseInt(Math.abs(entMeasureTime.getTime() - startMeasureTime.getTime()) / 1000 / 60 / 60 /
				24) + 1;
			if (sessionStorage.getItem('searchTime')) {
				var searchTime = sessionStorage.getItem('searchTime').split(',')
				if (searchTime[0] !== this.searchTime[0] || searchTime[1] !== this.searchTime[1]) {
					sessionStorage.setItem("searchTime", this.searchTime);
				}
			} else {
				sessionStorage.setItem("searchTime", this.searchTime);
			}
			this.startTime = this.searchTime[0];
			this.endTime = this.searchTime[1];
			this.doSearch = new Date().getTime();
		},
		doPDFExport() {
			document.querySelector('.el-collapse-item__wrap').style.display='block';
			document.querySelector('.el-tabs__header').style.display='none';
			document.querySelector('.pdfshow').style.display='none';
			document.querySelector('.pdftitle').style.display='block';
			if(this.statsActiveName === 'daily') {
				this.$PDFSave(document.querySelector('#forPDF'), '每日统计');
			} else {
				this.$PDFSave(document.querySelector('#forPDF'), '每周统计');
			}
			document.querySelector('.el-collapse-item__wrap').style.display='none';
			document.querySelector('.pdfshow').style.display='block';
			document.querySelector('.pdftitle').style.display='none';
			document.querySelector('.el-tabs__header').style.display='block';
			
			
		}

	},
};
