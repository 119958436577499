import "../../assets/style/cgmreport.css";

import Overview from '../../components/cgmReport/Overview.vue';
import Distribution from '../../components/cgmReport/Distribution.vue';
import Daily from '../../components/cgmReport/Daily.vue';
import Weekly from '../../components/cgmReport/Weekly.vue';
import Stats from '../../components/cgmReport/Stats.vue';
import Event from '../../components/cgmReport/Event.vue';

import Vue from 'vue';
import vueToPdf from 'vue-to-pdf';
Vue.use(vueToPdf)

export default {
    name: "CgmOverview",
	components: {
		Overview,
		Distribution,
		Daily,
		Weekly,
		Stats,
		Event
	},
	data() {
		return {
			cgmTableData: {},
			cgmUserInfo: {},
			activeName: 'overview',
			cgmreportShow: true,
			getPatientDetailUuid: '',
			sessionStorageUserType:'',
			sessionStorageUuid:'',
		}
	},
	created() {		
		this.sessionStorageUuid = sessionStorage.getItem('userUuid');
		this.getPatientDetailUuid = this.$route.query.getPatientDetailUuid;
		this.sessionStorageUserType = sessionStorage.getItem('userType');
		this.userType = this.$route.query.userType;
		this.cgmPatientDetail();
	},
	mounted() {
		sessionStorage.setItem("detail", true);
	},
	methods:{
		//CGM Report按钮显示隐藏
		cgmreportButShow(){
			this.cgmreportShow = !this.cgmreportShow;
		},
		//CGM Report 按钮
		cgmreportLink(){
			const uuid = this.getPatientDetailUuid;
			if(this.sessionStorageUserType == 3){
				this.$router.push({
					path: '/patient-detail',
					query: {
						getPatientDetailUuid: uuid,
						userType: 4,
						activeName:'third'
					}
				});
			}else{
				this.$router.push({
					path: '/patient/patient-detail',
					query: {
						getPatientDetailUuid: uuid,
						userType: 1,
						activeName:'third'
					}
				});
			}
		},
		//CGM获取患者信息
		cgmPatientDetail() {
			if(this.getPatientDetailUuid == null){
				this.getPatientDetailUuid=this.sessionStorageUuid;
			}
			this.$axios
				.get("/api/sys/web/patient/detail/" + this.getPatientDetailUuid)
				.then(response => {
					this.cgmTableData = response.data.data;
					console.log(this.cgmTableData)
					if(response.data.data.user !== null) {
						this.cgmUserInfo = response.data.data.user;
						if(this.cgmUserInfo.mobile){
							this.cgmUserInfo.mobile = this.cgmUserInfo.mobile.substring(0,3) + "****" 
								+ this.cgmUserInfo.mobile.substring(7,12);
						}
						if(this.cgmUserInfo.contactMobile){
							this.cgmUserInfo.contactMobile = this.cgmUserInfo.contactMobile.substring(0,3) + "****" 
								+ this.cgmUserInfo.contactMobile.substring(7,12);
							}
						if(this.cgmUserInfo.name){
							var name = this.cgmUserInfo.name;
							if(name.length>2){
								name = name.substring(0,1) + "***" + name.substring(name.length-1,name.length);
							}else{
								name = name.substring(0,1) + "*";
							}
							this.cgmUserInfo.name = name;
						}
						if(this.cgmUserInfo.email){
							var email = this.cgmUserInfo.email;
							var index = email.indexOf("@");
							if(index>4){
								email = email.substring(0,2) + "******" + email.substring(index, email.length);
							}else{
								email = email.substring(0,1) + "******" + email.substring(index, email.length);
							}
							this.cgmUserInfo.email=email;
						}
					}
				})
				.catch(e => {
					console.log(e);
					/* this.$message({
						message: "请求失败",
						type: "error"
					}); */
				});
		},
	},
	destroyed(){
		//sessionStorage.removeItem("searchTime")
	}
}
