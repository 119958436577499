import {
	parseDate
} from "../../../utils/convert.js"
export default {
    name: "StatsWeekly",
    data() {
        return {
            loading: true,

            weeklyStats: [],

            idStatsWeeklyTir: null,
            tirWeeklyChart: [],
            chartInstance: {},

            timesParam: []
        }
    },
    props: {
        puid: String,
        startTime: String,
        endTime: String,
        dateDiff: Number
    },
    created() {
        this.doSearch();
    },
    methods: {
        getMondayOrSunday(ddd, type) {
            let d = new Date(ddd);
            let oneDayTime = 24*60*60*1000;

            let day = d.getDay() || 7;

            // Monday
            if(type === 1) {
                let ret = d.getTime() - (day-1)*oneDayTime;
                return parseDate(new Date(ret));
            } else {
                // Sunday
                let ret = d.getTime() + (14-day)*oneDayTime;
                return parseDate(new Date(ret));
            }
        },
        dateAddorSub(ddd, duration, type) {
            let d = new Date(ddd);
            let oneDayTime = 24*60*60*1000;

            // add 
            if(type === 1) {
                let ret = d.getTime() + duration*oneDayTime;
                return parseDate(new Date(ret));
            } else {
                // sub
                let ret = d.getTime() - duration*oneDayTime;
                return parseDate(new Date(ret)); 
            }
        },
        compareDate(date1, date2) {
            let oDate1 = new Date(date1);
            let oDate2 = new Date(date2);

            if(oDate1.getTime() === oDate2.getTime()) {
                return true;
            } else if(oDate1.getTime() > oDate2.getTime()) {
                return true;
            } else {
                return false;
            }
        },
        caculateWeek() {
            let that = this;
            let arr = [];
            let start = that.startTime;
            let end = that.endTime;

            let currMonday = that.getMondayOrSunday(end, 1);
            let lastMonday = that.dateAddorSub(currMonday, 7, 2);

            let startD = lastMonday;
            let endD = end;

            let loopFlag = true;
            while(loopFlag) {

                arr.push({
                    text: startD + ' ~ ' + endD,
                    start: startD + ' 00:00:00',
                    end: endD+ ' 23:59:59'
                });

                endD = that.dateAddorSub(startD, 1, 2);
                startD = that.dateAddorSub(endD, 13, 2);

                if(that.compareDate(start, startD)) {

                    // add last
                    arr.push({
                        text: start + ' ~ ' + endD,
                        start: start + ' 00:00:00',
                        end: endD + ' 23:59:59',
                    });

                    loopFlag = false;
                }
            }
            this.timesParam = arr;
        },
        doSearch() {
            this.caculateWeek();

            let startMeasureTime = this.startTime;
            let endMeasureTime = this.endTime;

            let params = {
                userUuid: this.puid,
                endMeasureTime: endMeasureTime + ' 23:59:59',
                startMeasureTime: startMeasureTime + ' 00:00:00',
                times: this.timesParam
            };
            console.log(params);

            console.log(JSON.stringify(params));

            this.tirWeeklyChart.splice(0, this.tirWeeklyChart.length); //清空数组
			this.weeklyStats.splice(0, this.weeklyStats.length); //清空数组
            this.$axios
                .post('/api/his/web/patientCgmData/findStatsWeekly', params)
                .then(res => {
                    if(res.data.code === 200) {

                        this.weeklyStats = res.data.data;
                        console.log("=========>", this.weeklyStats);
                        this.$nextTick(() => {
                            for (let j = 0; j < this.weeklyStats.length; j++) {

                                for (let i = 0; i < this.weeklyStats[j].data.length; i++) {
                                    this.tirWeeklyChart = [{
                                            value: parseFloat(this.weeklyStats[j].data[i].veryLowPrecision),
                                        },
                                        {
                                            value: parseFloat(this.weeklyStats[j].data[i].lowPrecision),
                                        },
                                        {
                                            value: parseFloat(this.weeklyStats[j].data[i].inRangePrecision),
                                        },
                                        {
                                            value: parseFloat(this.weeklyStats[j].data[i].highPrecision),
                                        },
                                        {
                                            value: parseFloat(this.weeklyStats[j].data[i].veryHighPrecision),
                                        }];

                                    console.log("---------", this.tirWeeklyChart);
                                    this.idStatsWeeklyTir = 'statsWeeklyTir' + [i] + [j];
                                    this.initchart();
                                }
                            }
                            
                        });

                        this.loading = false;
                    }
                });
        },
        initchart() {
            let idWeeklyStatsTirs = document.getElementById(this.idStatsWeeklyTir);
            this.chartInstance = this.$echarts.init(idWeeklyStatsTirs);
            const initOption = {
                tooltip: {
                    trigger: 'item'
                },
                series: [{
                    name: '',
                    type: 'pie',
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    labelLine: {
                        show: false
                    },
                    color: ['#B93333', '#FF6060', '#26AB40', '#FAD555', '#FFA74A'],
                    data: this.tirWeeklyChart
                }]
            };
            this.chartInstance.setOption(initOption);
        }
    }
}
