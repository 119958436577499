import {
	xData
} from "../../utils/chartXData.js"
import {
	parseDate
} from "../../utils/convert.js"
export default {
	name: 'Daily',
	data() {
		return {
			minOptionDate:0,
			maxOptionDate:0,			
			get pickerOptions(){
				var that= this
				return {
					onPick: (obj) => {
						that.minOptionDate = new Date(obj.minDate).getTime();
						that.maxOptionDate = new Date(obj.maxDate).getTime();
					},
					disabledDate(time) {
						//就是这个之前的和这个之后的都
						if(that.minOptionDate>0 || that.maxOptionDate>0){//如果两个都选择了，那应该是根据哪个看起呢
							var threeMonthsAfter = null;
							var threeMOnthsBefore = null;
							let three = 59 * 24 * 3600 * 1000;
							if(that.minOptionDate>0){//这样应该怎么来设定呢？？？？								
								threeMonthsAfter = that.minOptionDate + three;
							}
							if(that.maxOptionDate>0){
								threeMOnthsBefore = that.minOptionDate - three;								
							}
							
							return time.getTime() > Date.now() || 
								(threeMonthsAfter!=null && time.getTime() > threeMonthsAfter) || 
								(threeMOnthsBefore!=null && time.getTime() < threeMOnthsBefore);
						}else{
							return time.getTime() > Date.now();
						}
					},
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近两个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
							picker.$emit('pick', [start, end]);
						}
					}]
				}
			},
			searchTime: null,
			chartInstance: null,
			//时间选择器选择时间差
			iDays: 60,
			DailyCharsData: [],
			alarmtime: '',
			dailyId: null,
			8.8: null,
			Width: '',
			xAxisDataAll: [],
			tirChart: [],
			yAxisData: [],
			allMinutes: [],
			loading: true,
			
			// 默认6页，共60天，每页10天
			totalPages: null,
			total: null,
			currPage: 1,
			currSize: 6,
			//报警列表展示，1--收起；2--展开
			//alarmList:true,
			logListHeight:'250px'
		}
	},
	props: {
		puid: String
	},
	created() {
		window.addEventListener('resize', this.innerWidth);
		this.getWidth();
		this.getSearchTime();
		this.getxAxisDataAll();
	},
	destroyed() {
		window.removeEventListener('resize', this.innerWidth)
	},
	methods: {
		changeDate(){
			this.minOptionDate=0;
			this.maxOptionDate=0;
		},
		getAlarmList(ii,e) {
			const alarmList = 'alarmList' + ii
			const iconAlarmlist = 'icon_alarmlist' + ii
			const className = document.getElementById(alarmList).className;
			const alarmListShow = className.search('alarmlist_up') != -1;
			console.log(e,111111)
			if(e<6){
				document.getElementById(alarmList).classList.remove('alarmlist_up')
				document.getElementById(alarmList).classList.add('alarmlist_down')
			}else{
				if (alarmListShow) {
					this.logListHeight = '250px'
					document.getElementById(alarmList).classList.add('alarmlist_down')
					document.getElementById(alarmList).classList.remove('alarmlist_up')
					document.getElementById(iconAlarmlist).classList.add('el-icon-arrow-up')
					document.getElementById(iconAlarmlist).classList.remove('el-icon-arrow-down')
				} else {
					this.logListHeight = 'auto'
					document.getElementById(alarmList).classList.add('alarmlist_up')
					document.getElementById(alarmList).classList.remove('alarmlist_down')
					document.getElementById(iconAlarmlist).classList.add('el-icon-arrow-down')
					document.getElementById(iconAlarmlist).classList.remove('el-icon-arrow-up')
				}
			}
		},
		getWidth() {
			this.Width = window.innerWidth - 282 + 'px';
		},
		handleSizeChange(newSize) {
			this.currSize = newSize;
		},
		handleCurrentChange(newPage) {
			this.loading = true;
			this.currPage = newPage;
			const entMeasureTime = new Date(Date.parse(this.searchTime[1].replace(/-/g, "/")));
			const startMeasureTime = new Date(Date.parse(this.searchTime[0].replace(/-/g, "/")));
			
			this.doSearch(parseDate(startMeasureTime), parseDate(entMeasureTime));
		},
		//x轴整体数据
		getxAxisDataAll() {
			var xAxisData = [];
			var xMinute = [];
			var xHour = [];
			for (var min = 0; min < 60; min++) {
				var setMin = min + '';
				if (setMin < 10) {
					setMin = '0' + setMin
				}
				xMinute.push(setMin)
			}
			for (var hour = 0; hour < 24; hour++) {
				var setHour = hour + '';
				if (setHour < 10) {
					setHour = '0' + setHour
				}
				xHour.push(setHour);
			}
			for (var h = 0; h < 24; h++) {
				for (var s = 0; s < 60; s++) {
					var setTime = xHour[h] + ":" + xMinute[s]
					xAxisData.push(setTime);
				}
			}
			this.xAxisDataAll = xAxisData;
		},
		getxAxisData() {
			let newDate = new Date();
			let yy = newDate.getFullYear();
			let mm = newDate.getMonth() + 1;
			mm = mm < 10 ? '0' + mm : mm;
			let dd = newDate.getDate();
			dd = dd < 10 ? '0' + dd : dd;
			let forCal = new Date(yy + '-' + mm + '-' + dd + ' 00:00:00');
			let t_s = forCal.getTime();

			for (var ii = 0; ii < this.DailyCharsData.length; ii++) {
				var vm = this;
				vm.$set(this.DailyCharsData[ii], 'allMinutes', []);
				for (let i = 0; i < 1440; i++) {
					forCal.setTime(t_s + 1000 * 60 * i);
					let newHour = forCal.getHours() < 10 ? '0' + forCal.getHours() : forCal.getHours();
					let newMin = forCal.getMinutes() < 10 ? '0' + forCal.getMinutes() : forCal.getMinutes();
					var newtime = this.DailyCharsData[ii].date + ' ' + newHour + ':' + newMin + ':00';
					this.DailyCharsData[ii].allMinutes.push(newtime);
				}
			}
		},
		//默认选择60天日期
		getSearchTime() {
			/* let nowDate = new Date();
			let endTime = new Date();
			let startTime = new Date(nowDate.setDate(nowDate.getDate() - 13));
			this.searchTime = [parseDate(startTime), parseDate(endTime)];
			console.log(new Date(Date.parse(this.searchTime[1].replace(/-/g, "/"))))
			debugger
			this.iDays = parseInt(Math.abs(endTime.getTime() - startTime.getTime()) / 1000 / 60 / 60 /
				24) + 1;
			
			this.doSearch(parseDate(startTime), parseDate(endTime)); */
			let nowDate = new Date();
			if (sessionStorage.getItem('searchTime')) {
				console.log(sessionStorage.getItem('searchTime'))
				var searchTime = sessionStorage.getItem('searchTime').split(',')
				this.searchTime = [searchTime[0], searchTime[1]];
			} else {
				let endTime = parseDate(nowDate);
				let startTime = parseDate(new Date(nowDate.setDate(nowDate.getDate() - 13)));
				this.searchTime = [startTime, endTime];
				sessionStorage.setItem("searchTime", this.searchTime);
			}
			const entMeasureTime = new Date(Date.parse(this.searchTime[1].replace(/-/g, "/")));
			const startMeasureTime = new Date(Date.parse(this.searchTime[0].replace(/-/g, "/")));
			this.iDays = parseInt(Math.abs(entMeasureTime.getTime() - startMeasureTime.getTime()) / 1000 / 60 / 60 /
				24) + 1;
			this.doSearch(this.searchTime[0],this.searchTime[1]);
		},
		// 排序
		dataOrderBy(ddd) {
			let data = ddd.concat([]);
			data = data.sort(function(a, b) {
				return Date.parse(b.date + ' 00:00:00') - Date.parse(a.date + ' 00:00:00');
			});
			return data;
		},
		// 报警排序
		warningListOrderBy(arr) {
			let data = arr === null ? [] : arr.concat([]);
			if (data.length > 0) {
				data = data.sort(function(a, b) {
					return parseInt(b.level) - parseInt(a.level);
				});
				return data;
			}
			return arr;
		},
		doSearch(s, e) {
			console.log(s);
			console.log(e);

			var params = {
				userUuid: this.puid,
				endCreateTime: e + ' 23:59:59',
				startCreateTime: s + ' 00:00:00',
				page: this.currPage,
				size: this.currSize
			}
			console.log("params", params);
			this.yAxisData.splice(0, this.yAxisData.length); //清空数组
			this.$data.DailyCharsData = Object.assign([], this.$data.DailyCharsData); //清空对象
			this.$axios.post('/api/his/web/patientWarningLog/newlist', params).then(response => {
				// post 成功，response.data 为返回的数据
				this.DailyCharsData = this.dataOrderBy(response.data.data);
				this.totalPages = response.data.pageData.totalPages;
				this.total = response.data.pageData.totalElements;
				console.log("response.data.data", response.data);
				this.getxAxisData();
				this.$nextTick(() => {
					for (var i = 0; i < this.DailyCharsData.length; i++) {
						this.dailyId = 'dailyId' + [i];
						this.alarmtime = this.DailyCharsData[i].date;
						//var vm = this;
						var allMinutes = this.DailyCharsData[i].allMinutes;
						var yAxisData = new Array(1440);
						if (this.DailyCharsData[i].listCgmData !== null) {
							this.DailyCharsData[i].listCgmData.forEach(item1 => {
								for (var setnum = 0; setnum < 1440; setnum++) {
									if (item1.measureTime == allMinutes[setnum]) {
										yAxisData[setnum] = item1.glucose;
									}
								}
								this.DailyCharsData[i].yAxisData = yAxisData;
							})
						}
						this.yAxisData = this.DailyCharsData[i].yAxisData
						this.initchart();
					}
					this.loading = false;
				});
			}).catch(error => {
				// 请求失败
				console.log(error)
				/* this.$message({
					message: "请求失败",
					type: "error"
				}); */
			})
		},
		//点击查询按钮按照日期查询
		doSearchTime() {
			this.loading = true;
			const entMeasureTime = new Date(Date.parse(this.searchTime[1].replace(/-/g, "/")));
			const startMeasureTime = new Date(Date.parse(this.searchTime[0].replace(/-/g, "/")));
			this.iDays = parseInt(Math.abs(entMeasureTime.getTime() - startMeasureTime.getTime()) / 1000 / 60 / 60 /
				24) + 1;
			if (sessionStorage.getItem('searchTime')) {
				var searchTime = sessionStorage.getItem('searchTime').split(',')
				if (searchTime[0] !== this.searchTime[0] || searchTime[1] !== this.searchTime[1]) {
					sessionStorage.setItem("searchTime", this.searchTime);
				}
			} else {
				sessionStorage.setItem("searchTime", this.searchTime);
			}
			this.currPage=1;//点击查询得时候默认为1
			//this.doSearch(this.searchTime[0],this.searchTime[1]);
			this.doSearch(parseDate(startMeasureTime), parseDate(entMeasureTime));
		},
		initchart() {
			this.chartInstance = this.$echarts.init(document.getElementById(this.dailyId));
			const initOption = {
				tooltip: {
					trigger: 'axis',
					formatter(params){
						var startTime = new Date();
						startTime.setHours(0);
						startTime.setMinutes(0);
						startTime.setMinutes(startTime.getMinutes() + params[0].dataIndex);     
						var hour=  startTime.getHours();
						var mins = startTime.getMinutes();
						if(hour<10)
							hour="0"+hour;
						if(mins<10)
							mins="0" + mins;
						var valueStr = params[0].value;
						if(valueStr==null || valueStr==undefined || valueStr=="N/A")
							return null;
						else{
							valueStr = params[0].value.toFixed(1);
							return valueStr + " mmol/L" + "<br/>" + hour+":"+mins;
						}
					}
				},
				grid: {
					left: '30',
					right: '30',
				},
				title: {
					text: this.alarmtime,
					top: '10',
				},
				xAxis: {
					type: 'category',
					data: xData,
					boundaryGap: false,
					axisLabel: {
						interval: 0
					},
					axisTick: {
						interval: 59
					}
				},
				yAxis: {
					type: 'value',
					min: 0,
					max: 22,
					interval: 5,
				},
				//线的颜色样式
				visualMap: {
					top: 500,
					type: 'piecewise',
					//symbolSize: 80,
					pieces: [{
						gt: 0.1,
						lte: 3.9,
						color: '#FF6363',
					}, {
						gt: 3.9,
						lte: 10,
						color: '#4870FF',

					}, {
						gt: 10,
						color: '#FFF585',
					}],
				},
				series: [{
					name: '血糖值',
					type: 'line',
					smooth: true,
					data: this.yAxisData,
					//是否显示节点的○符号
					showSymbol: false,
					lineStyle: {
						width: 2
					},
					markArea: { //标记区域
						data: [
							[{
								yAxis: '3.9',
								itemStyle: {
									color: '#4870FF23'
								}
							}, {
								yAxis: '10'
							}]
						]
					},
					//分割线
					/* markLine: {
						symbolSize: [0, 0],
						silent: true,
						data: [{
							yAxis: 3.9,
							lineStyle: {
								color: '#f33131',
								width: 1.5,
								type: 'solid'
							},
						}, {
							yAxis: 10,
							lineStyle: {
								color: '#f1b907',
								width: 1.5,
								type: 'solid'
							},
						}]
					}, */
				}]
			};
			this.chartInstance.setOption(initOption);
			window.addEventListener("resize", () => {
				this.chartInstance.resize();
			});
			this.loading = false;
		},
	},
};
